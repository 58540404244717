'use client'

import React from 'react'
import NextLink from 'next/link'
import { Box, Button, Container, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from 'react-responsive'

import useLang from 'translations'

const NotFound = () => {
  const { t } = useLang()
  const theme = useTheme()
  const mobileDevice = useMediaQuery({ minWidth: 600 })

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        flexGrow: 1,
        py: '80px'
      }}
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
        >
          404: {t('errors.404')}
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          sx={{ mt: 0.5 }}
          variant="subtitle2">
          {t('errors.404')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >
          <Box
            alt="Under development"
            component="img"
            src={`/s/error/error404_${theme.palette.mode}.svg`}
            sx={{
              height: 'auto',
              maxWidth: '100%',
              width: 400
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}>
          <NextLink href="/" passHref>
            <Button component="a" variant="outlined">{t('general.back')}</Button>
          </NextLink>
        </Box>
      </Container>
    </Box>
  )
}

export default NotFound
